$beige: #F5F3EF
$red: #ff0000
$grey: #8D8D8D

$break375: 375px
$break30: 30rem
$break48: 48rem
$break62: 62rem
$break75: 75rem

$font-title: modern_gothic_poster

$font-text: modern_gothicregular

$font-light: modern_gothiclight

$contentWidth-normal: 56.25rem // 56.25rem == 900px
$contentWidth-wide: 87.5rem // 87.5rem == 1400px
