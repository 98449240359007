@use 'common'

@font-face
	font-family: 'modern_gothic_poster'
	src: url('/fonts/moderngothicbeta2022-poster-webfont.woff2') format('woff2'), url('/fonts/moderngothicbeta2022-poster-webfont.woff') format('woff')
	font-weight: 900
	font-style: normal

@font-face
	font-family: 'modern_gothicregular'
	src: url('/fonts/moderngothic-regular-webfont.woff2') format('woff2'), url('/fonts/moderngothic-regular-webfont.woff') format('woff')
	font-weight: 400
	font-style: normal

@font-face
	font-family: 'modern_gothiclight'
	src: url('/fonts/moderngothic-light-webfont.woff2') format('woff2'), url('/fonts/moderngothic-light-webfont.woff') format('woff')
	font-weight: 300
	font-style: normal

html,
body
	color: #000000
	background-color: common.$beige
	padding: 0
	margin: 0
	font-family: modern_gothiclight, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	scroll-behavior: smooth
a
	color: inherit
	text-decoration: none

p
	font-size: 1.25rem
	line-height: 1.5rem

*
	box-sizing: border-box

:root
	--global-horizontal-spacing: 1rem

	--content-width-normal: #{common.$contentWidth-normal}
	--content-width-wide: #{common.$contentWidth-wide}

	@media (min-width: common.$break62)
		--global-horizontal-spacing: 2rem
